export * from './src/makeApiClient'
export * from './src/FunctionUrlClient'
export * from './src/fetchServiceConfig'

export * from './src/makeApiGatewayClient'

        import * as me from '@peachy/core-domain-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/manhnguyen/workspace/peachy-mono-repo/core-domain/client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/core-domain-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    